import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    padding: 0
  },
  listIcon: {
    marginRight: theme.spacing(1)
  },
  listItemNested: {
    paddingLeft: theme.spacing(2)
  },
  listItemDoubleNested: {
    paddingLeft: theme.spacing(4)
  },

  routeListHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  routeListMoreButton: {
    margin: -theme.spacing(1),
    padding: 0,
    width: 35,
    height: 35,
    color: theme.palette.text.hint,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "rgba(255, 255, 255, 0.35)"
    }
  }
}));
