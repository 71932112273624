import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet-polylinedecorator";

// styles
import "leaflet/dist/leaflet.css";
import useStyles from "./styles";

import { useMapDispatch, useMapState } from "../../context/MapContext";

export default function Map(props) {
  const colorArray = [
    "#FF6633",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF"
  ];

  // global
  var mapState = useMapState();
  var mapDispatch = useMapDispatch();

  //local
  var classes = useStyles();
  const mapRef = useRef();
  const markersRef = useRef({});
  const pathRef = useRef();
  const pathDecoratorRef = useRef();

  const [isMarkerDragging, setMarkerDragging] = useState(false);

  // Initialize map and empty polyline on mount
  useEffect(function onMount() {
    mapRef.current = L.map("map", {
      zoomControl: false,
      layers: [
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png")
      ]
    }).setView([54.6926355, 25.2371336], 9);

    // Cleanup on potential onmount
    return () => {
      mapRef.current && mapRef.current.remove();
    };
  }, []);

  useEffect(() => {
    addOrRemoveMarkersFromMap();
  }, [mapState.markers]);

  // Part of workaround for https://github.com/Leaflet/Leaflet/issues/6112
  useEffect(
    function onMarkerBeingEdited() {
      if (mapState.markerBeingEdited === undefined) {
        document.getElementById("map").style.cursor = "";
        return;
      }
      document.getElementById("map").style.cursor = "crosshair";
      mapRef.current.on("click", e => {
        if (!isMarkerDragging) {
          mapDispatch({
            type: "ENSURE_MARKER",
            payload: {
              ...mapState.markerBeingEdited,
              latitude: e.latlng.lat,
              longitude: e.latlng.lng
            }
          });
          mapDispatch({ type: "SET_MARKER_BEING_EDITED", payload: undefined });
        }
      });
      return () => mapRef.current.off("click");
    },
    [mapState.markerBeingEdited]
  );

  // One solution would probably be to completely remove all markers from the map whenever they change and create new ones
  function addOrRemoveMarkersFromMap() {
    for (var key in markersRef.current) {
      markersRef.current[key].remove();
    }
    markersRef.current = {};
    mapState.markers && mapState.markers.forEach(createMarker);

    // if (!prevMarkers.current || mapState.markers.length > prevMarkers.current.length) {
    //   const addedMarkers = difference(mapState.markers, prevMarkers.current);
    //
    //   // Add new markers when they are added, attach drag event to them
    //   addedMarkers.forEach(createMarker);
    // } else if (mapState.markers.length < prevMarkers.current.length) {
    //   const removedMarker = difference(prevMarkers.current, mapState.markers)[0];
    //
    //   // Remove existing marker from map when waypoint is removed
    //   getMarker(removedMarker).remove();
    //   delete markersRef.current[removedMarker.id];
    // }
  }

  function createMarker(marker) {
    let leafletMarker = L.marker([marker.latitude, marker.longitude]);
    let isDepot = marker.type && marker.type === "DEPOT";
    let className;
    if (marker.disabled) {
      className = isDepot
        ? classes.depotIconDisabled
        : classes.destinationIconDisabled;
    } else {
      className = isDepot ? classes.depotIcon : classes.destinationIcon;
    }

    leafletMarker.setIcon(
      new L.DivIcon({
        iconSize: 25,
        className: className,
        html: marker.name
          ? marker.name
              .split(" ")
              .map(i => i.charAt(0))
              .slice(0, 3)
              .join("")
              .toUpperCase()
          : ""
      })
    );

    leafletMarker.addTo(mapRef.current);

    markersRef.current[marker.id] = leafletMarker;
  }

  //Routing
  useEffect(() => {
    pathRef.current &&
      pathRef.current.forEach(p => {
        p.remove();
      });
    pathDecoratorRef.current &&
      pathDecoratorRef.current.forEach(p => {
        p.remove();
      });

    let polylines = [];
    let decorators = [];
    mapState.routes !== undefined &&
      mapState.routes.forEach((r, i) => {
        let polyline = L.polyline(r.polyline, {
          weight: 3,
          color: colorArray[i]
        }).addTo(mapRef.current);
        polylines.push(polyline);

        let decorator = L.polylineDecorator(r.polyline, {
          patterns: [
            {
              offset: 25,
              repeat: 50,
              symbol: L.Symbol.arrowHead({
                pixelSize: 11,
                pathOptions: { fillOpacity: 1, weight: 0, color: colorArray[i] }
              })
            }
          ]
        }).addTo(mapRef.current);
        decorators.push(decorator);
      });
    pathRef.current = polylines;
    pathDecoratorRef.current = decorators;
  }, [mapState.routes]);

  useEffect(
    function onPanToChange() {
      if (mapState.panTo) {
        mapRef.current.flyTo(
          mapState.panTo,
          Math.max(mapRef.current.getZoom(), 10),
          { easeLinearity: 0.8, duration: 0.15 }
        );
      }
    },
    [mapState.panTo]
  );

  return (
    <div id="map-container" className={classes.mapContainer}>
      <div id="map" style={{ height: "100%" }} />
    </div>
  );
}
