import React from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
// components
import Layout from "./Layout";
// pages
import Error from "../pages/error";
import Login from "../pages/login";
// context
import { useUserState } from "../context/UserContext";
import { toast, ToastContainer } from "react-toastify";
//icons
import { Close as CloseIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";

export default function App() {
  // global
  var theme = useTheme();
  var { isAuthenticated } = useUserState();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
        closeButton={
          <CloseButton
            style={{
              position: "absolute",
              right: theme.spacing(2)
            }}
          />
        }
        style={{
          width: 400,
          marginTop: theme.spacing(6),
          right: 0
        }}
      />
      <HashRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/app/route" />} />
          <Route
            exact
            path="/app"
            render={() => <Redirect to="/app/route" />}
          />
          <PrivateRoute path="/app" component={Layout} />
          <PublicRoute path="/login" component={Login} />
          <Route component={Error} />
        </Switch>
      </HashRouter>
    </>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location
                }
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/"
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

export { SendErrorNotification, SendSuccessNotification };

// #############################################################
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

function SendErrorNotification(message) {
  toast.error("   " + message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: false
  });
}

function SendSuccessNotification(message) {
  toast.success("   " + message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    pauseOnHover: false,
    draggable: false
  });
}
