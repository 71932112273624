import React from "react";

var LibraryStateContext = React.createContext();
var LibraryDispatchContext = React.createContext();

function libraryReducer(state, action) {
  switch (action.type) {
    case "INIT_DATA":
      return { ...initializeDataIfNotFound(state) };
    case "RESET_DATA":
      console.log("RESET_DATA");
      return { ...resetData(state) };
    case "UPDATE_DEPOTS":
      persistInBrowser("depots", action.payload);
      return {
        ...state,
        depots: action.payload
      };
    case "UPDATE_DESTINATIONS":
      persistInBrowser("destinations", action.payload);
      return {
        ...state,
        destinations: action.payload
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LibraryProvider({ children }) {
  var [state, dispatch] = React.useReducer(libraryReducer, {
    destinations: loadFromBrowser("destinations") || [],
    depots: loadFromBrowser("depots") || []
  });

  return (
    <LibraryStateContext.Provider value={state}>
      <LibraryDispatchContext.Provider value={dispatch}>
        {children}
      </LibraryDispatchContext.Provider>
    </LibraryStateContext.Provider>
  );
}

function useLibraryState() {
  var context = React.useContext(LibraryStateContext);
  if (context === undefined) {
    throw new Error("uselibraryState must be used within a libraryProvider");
  }
  return context;
}

function useLibraryDispatch() {
  var context = React.useContext(LibraryDispatchContext);
  if (context === undefined) {
    throw new Error("uselibraryDispatch must be used within a libraryProvider");
  }
  return context;
}

export { LibraryProvider, useLibraryState, useLibraryDispatch };

// ###########################################################

function resetData(state) {
  removeFromBrowser("destinations");
  removeFromBrowser("depots");
  initializeDataIfNotFound(state);
}

function initializeDataIfNotFound(state) {
  let destinations = loadFromBrowser("destinations");
  let depots = loadFromBrowser("depots");
  if (destinations !== undefined && depots !== undefined) {
    return state;
  }

  let newState = {
    ...state,
    destinations: [
      {
        id: "DESTINATION_0",
        type: "DESTINATION",
        name: "VIP",
        demand: 3,
        description: "VIP klientas",
        address:
          "A2, Laukeliai, Avižienių seniūnija, Vilniaus rajono savivaldybė, Vilnius County, 14200, Lithuania",
        latitude: 54.81666500906162,
        longitude: 25.144958496093754
      },
      {
        id: "DESTINATION_1",
        type: "DESTINATION",
        name: "Sodo parduotuve",
        demand: 2,
        description:
          "Alksninė, Kaušiadala, Paberžės seniūnija, Vilniaus rajono savivaldybė, Vilnius County, Lithuania",
        address: "",
        latitude: 54.91765382575062,
        longitude: 25.18392562866211
      },
      {
        id: "DESTINATION_2",
        type: "DESTINATION",
        name: "Sodo parduotuve 2",
        demand: 1,
        description:
          "8, Skroblų g., Kauno-Algirdo kvartalas, Naujamiesčio seniūnija, Vilnius, Vilnius city municipality, Vilnius County, 03142, Lithuania",
        address: "",
        latitude: 54.670602296033636,
        longitude: 25.255336761474613
      },
      {
        id: "DESTINATION_3",
        type: "DESTINATION",
        name: "Atsargines dalys",
        demand: 4,
        description:
          "Abraomo Kulviečio klasikinė gimnazija, 8, Gedvydžių g., Fabijoniškių seniūnija, Vilnius, Vilnius city municipality, Vilnius County, 06306, Lithuania",
        address: "",
        latitude: 54.737650533423164,
        longitude: 25.238900184631348
      },
      {
        name: "Atsargins dalys 2",
        type: "DESTINATION",
        id: "DESTINATION_4",
        demand: 3,
        address:
          "4A, Baltijos g., Vilijampolės seniūnija, Kaunas, Kauno miesto savivaldybė, Kaunas County, 47135, Lithuania",
        latitude: 54.92451246416447,
        longitude: 23.890800476074222
      },
      {
        name: "Atsargines dalys 3",
        type: "DESTINATION",
        id: "DESTINATION_5",
        demand: 5,
        address:
          "19B, S. Kerbedžio g., Senamiestis, Panevėžys, Panevėžio miesto savivaldybė, Panevezys County, 35113, Lithuania",
        latitude: 55.74392288382254,
        longitude: 24.375915527343754
      },
      {
        name: "Klientas 1",
        type: "DESTINATION",
        id: "DESTINATION_6",
        demand: 2,
        address:
          "Via Baltica, Aukštadvaris, Daukšynė, Ramygalos seniūnija, Panevėžio rajono savivaldybė, Kaunas County, 58462, Lithuania",
        latitude: 55.46837422154776,
        longitude: 24.28390502929688
      },
      {
        name: "Klientas 2",
        type: "DESTINATION",
        id: "DESTINATION_7",
        demand: 3,
        address:
          "Sodeliškių g., Lukštės, Sodeliškiai, Raguvos seniūnija, Ukmergės rajono savivaldybė, Panevezys County, Lithuania",
        latitude: 55.490065403428616,
        longitude: 24.6368408203125
      },
      {
        name: "Klientas 3",
        type: "DESTINATION",
        id: "DESTINATION_8",
        demand: 1,
        address:
          "Duburiai, Dukstyna, Vidiškių seniūnija, Ukmergės rajono savivaldybė, Vilnius County, 20188, Lithuania",
        latitude: 55.27229195326867,
        longitude: 24.815368652343754
      },
      {
        name: "Klientas 4",
        type: "DESTINATION",
        id: "DESTINATION_9",
        demand: 3,
        address:
          "Kėdainiai — Šėta — Ukmergė, Benediktava, Tarakai, Siesikų seniūnija, Ukmergės rajono savivaldybė, Vilnius County, 55458, Lithuania",
        latitude: 55.22658181088989,
        longitude: 24.485778808593754
      },
      {
        name: "Klientas 5",
        type: "DESTINATION",
        id: "DESTINATION_10",
        demand: 2,
        address:
          "Parčevskių g., Karklinė, Sklėriškės, Nemenčinės seniūnija, Vilniaus rajono savivaldybė, Vilnius County, 15168, Lithuania",
        latitude: 54.898214883759366,
        longitude: 25.556945800781254
      },
      {
        name: "Klientas 6",
        type: "DESTINATION",
        id: "DESTINATION_11",
        demand: 6,
        address:
          "Nemenčinės pl., Liepalotai, Miškonys, Bezdonių seniūnija, Vilniaus rajono savivaldybė, Vilnius County, 15181, Lithuania",
        latitude: 54.820615142998875,
        longitude: 25.42373657226563
      },
      {
        name: "Klientas 7",
        type: "DESTINATION",
        id: "DESTINATION_12",
        demand: 2,
        address:
          "Baltadvario g., Pabiržė, Videniškiai, Videniškių seniūnija, Molėtų rajono savivaldybė, Utena County, Lithuania",
        latitude: 55.22416915089574,
        longitude: 25.2685546875
      },
      {
        name: "Klientas 8",
        type: "DESTINATION",
        id: "DESTINATION_13",
        demand: 4,
        address:
          "116, Vileikiškiai, Musninkų seniūnija, Širvintų rajono savivaldybė, Vilnius County, 19182, Lithuania",
        latitude: 54.98807507222514,
        longitude: 24.884033203125004
      },
      {
        name: "Klientas 9",
        type: "DESTINATION",
        id: "DESTINATION_14",
        demand: 2,
        address:
          "Lielupės g., Boguliuba, Kernavė, Kernavės seniūnija, Širvintų rajono savivaldybė, Vilnius County, 19172, Lithuania",
        latitude: 54.89192328501255,
        longitude: 24.853820800781254
      }
    ],
    depots: [
      {
        id: "DEPOT_0",
        type: "DEPOT",
        name: "Pirmas sandelis",
        description: "Pirmas sandelis",
        address:
          "12, Antakalnio g., Antakalnio seniūnija, Vilnius, Vilnius city municipality, Vilnius County, 10305, Lithuania",
        latitude: 54.69627893893384,
        longitude: 25.308165550231934
      }
    ]
  };

  persistInBrowser("destinations", newState.destinations);
  persistInBrowser("depots", newState.depots);

  return newState;
}

function persistInBrowser(key, data) {
  localStorage.setItem("roadgen:" + key, JSON.stringify(data));
}

function removeFromBrowser(key) {
  localStorage.removeItem("roadgen:" + key);
}

function loadFromBrowser(key) {
  const loadedData = localStorage.getItem("roadgen:" + key);
  if (!loadedData) {
    return undefined;
  }
  try {
    return JSON.parse(loadedData) || undefined;
  } catch {
    return undefined;
  }
}
