export function generateGpxFile(name, waypoints, tracks) {
  return `<?xml version='1.0' encoding='UTF-8'?>
    <gpx version="1.1" creator="https://www.komoot.de" xmlns="http://www.topografix.com/GPX/1/1"
       xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
       xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">
      <metadata>
          <name>${name}</name>
          <author>
              <link href="https://www.komoot.de">
                  <text>komoot</text>
                  <type>text/html</type>
              </link>
          </author> 
      </metadata>
      ${waypoints
        .map(waypoint => {
          return `<wpt lat="${waypoint.coordinates.lat}" lon="${waypoint.coordinates.lon}">
                <name>${waypoint.name}</name>
                </wpt>`;
        })
        .join("")}
      ${tracks
        .map((track, idx) => {
          return `<trk>
                  <name>${track.name}</name>
                  <number>${idx + 1}</number>
                  <trkseg>
                  ${track.polyline
                    .map(latlng => {
                      return `<trkpt lat="${latlng[0]}" lon="${latlng[1]}">
                          <time>${new Date().toISOString()}</time>
                          </trkpt>`;
                    })
                    .join("")}
                  </trkseg>
                  </trk>`;
        })
        .join("")}
  </gpx>`;
}
