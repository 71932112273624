import React, { useEffect } from "react";
import { Grid, Switch } from "@material-ui/core";
import MaterialTable from "material-table";

// components
import Map from "../../components/Map";
// context
import { useMapDispatch, useMapState } from "../../context/MapContext";
import {
  useLibraryDispatch,
  useLibraryState
} from "../../context/LibraryContext";
//styles
import { withStyles } from "@material-ui/core/styles";
//colors
import { green, grey } from "@material-ui/core/colors";
import { reverse, search } from "../../util/nominatimAxios";
import { SendErrorNotification } from "../../components/App";
import { Save as SaveIcon } from "@material-ui/icons";

const GreenSwitch = withStyles({
  switchBase: {
    color: grey[50],
    "&$checked": {
      color: green[400]
    },
    "&$checked + $track": {
      backgroundColor: green[400]
    }
  },
  checked: {},
  track: {}
})(Switch);

export default function Depots() {
  // global
  var mapDispatch = useMapDispatch();
  var mapState = useMapState();
  var libraryState = useLibraryState();
  var libraryDispatch = useLibraryDispatch();

  //local
  const [state, setState] = React.useState({
    columns: [
      {
        title: "Disabled",
        field: "disabled",
        hidden: true,
        editable: "never",
        type: "boolean"
      },
      { title: "Name", field: "name" },
      { title: "Description", field: "description" },
      { title: "Address", field: "address" },
      {
        title: "Latitude",
        field: "latitude",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Longitude",
        field: "longitude",
        type: "numeric",
        editable: "never"
      }
    ],
    data: []
  });

  useEffect(function onMount() {
    const data = libraryState.depots;
    data.forEach((d, i) => {
      if (d.latitude !== undefined && d.longitude !== undefined) {
        mapDispatch({
          type: "ADD_MARKER",
          payload: d
        });
      }
    });

    setState(prevState => {
      return { ...prevState, data };
    });

    // Cleanup on potential onmount
    return () => {
      mapDispatch({
        type: "CLEAR",
        payload: undefined
      });
    };
  }, []);

  useEffect(
    function onDepotsChange() {
      libraryDispatch({
        type: "UPDATE_DEPOTS",
        payload: state.data
      });
    },
    [state.data]
  );

  useEffect(
    function onLatLngChange() {
      mapState.markers.forEach(m => {
        state.data.forEach((d, idx) => {
          if (m.id === d.id) {
            if (d.latitude === m.latitude || d.longitude === m.longitude) {
              return;
            }
            let newDep = {
              ...d,
              latitude: m.latitude,
              longitude: m.longitude
            };
            setState(prevState => {
              const data = [...prevState.data];
              data[idx] = newDep;
              return { ...prevState, data };
            });
            scheduleAddressUpdate(idx, d, newDep);
          }
        });
      });
    },
    [mapState.markers]
  );
  function addDepot(newDestination) {
    setState(prevState => {
      const data = [...prevState.data];
      data.push(identifiedDepot(newDestination, data.length));
      return { ...prevState, data };
    });
    if (
      newDestination.latitude !== undefined &&
      newDestination.longitude !== undefined
    ) {
      mapDispatch({
        type: "ADD_MARKER",
        payload: newDestination
      });
    }
    scheduleLocationUpdate(
      state.data.length - 1,
      newDestination,
      newDestination
    );
  }

  function updateDepotAt(idx, oldDepot, updatedDepot) {
    let newData = identifiedDepot(updatedDepot, idx);

    setState(prevState => {
      const data = [...prevState.data];
      data[idx] = newData;
      return { ...prevState, data };
    });
    mapDispatch({
      type: "REMOVE_MARKER",
      payload: newData.id
    });
    if (!!newData.latitude && !!newData.longitude) {
      mapDispatch({
        type: "ADD_MARKER",
        payload: newData
      });
    }

    scheduleLocationUpdate(idx, oldDepot, newData);
  }

  function scheduleLocationUpdate(idx, oldDepot, newData) {
    if (!scheduleDepotPositionUpdate(idx, oldDepot, newData)) {
      scheduleAddressUpdate(idx, oldDepot, newData);
    }
  }

  function scheduleDepotPositionUpdate(idx, oldDepot, newData) {
    if (
      !!newData.address &&
      (!newData.latitude ||
        !newData.longitude ||
        !oldDepot.address ||
        oldDepot.address !== newData.address)
    ) {
      search({
        q: newData.address,
        addressdetails: "1",
        limit: 1
      })
        .then(res => {
          if (res.length > 0) {
            newData.latitude = res[0].lat;
            newData.longitude = res[0].lon;
            newData.address = res[0].display_name;
            updateDepotAt(idx, newData, newData);
          } else {
            SendErrorNotification(
              "Unable to find position for address: '" + newData.address + "'"
            );
          }
        })
        .catch(e =>
          SendErrorNotification(
            "Failed find position for address: '" +
              newData.address +
              "'. Reason: " +
              e.message
          )
        );
      return true;
    }

    return false;
  }

  function scheduleAddressUpdate(idx, oldDepot, newData) {
    if (
      !!newData.latitude &&
      !!newData.longitude &&
      (!oldDepot.latitude ||
        !oldDepot.longitude ||
        oldDepot.latitude !== newData.latitude ||
        oldDepot.longitude !== newData.longitude)
    ) {
      //TODO:
      reverse(
        {
          addressdetails: "1",
          limit: 1
        },
        newData.latitude,
        newData.longitude
      )
        .then(res => {
          if (!!res) {
            newData.address = res.display_name;
            updateDepotAt(idx, newData, newData);
          } else {
            SendErrorNotification(
              "Unable to find address for position: {lat: '" +
                newData.latitude +
                "', lon: '" +
                newData.longitude +
                "'}"
            );
          }
        })
        .catch(e =>
          SendErrorNotification(
            "Unable to find address for position: {lat: '" +
              newData.latitude +
              "', lon: '" +
              newData.longitude +
              "'}"
          )
        );
    }
  }

  function identifiedDepot(depot, index) {
    return {
      ...depot,
      type: "DEPOT",
      id: "DEPOT_" + index
    };
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MaterialTable
            title="Depots"
            columns={state.columns}
            data={state.data}
            editable={{
              onRowAdd: newData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    addDepot(newData);
                  }, 200);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      updateDepotAt(oldData.tableData.id, oldData, newData);
                    }
                  }, 200);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setState(prevState => {
                      const data = [...prevState.data];
                      data.splice(oldData.tableData.id, 1);
                      return { ...prevState, data };
                    });
                    mapDispatch({
                      type: "REMOVE_MARKER",
                      payload: oldData.id
                    });
                  }, 200);
                })
            }}
            actions={[
              rowData => {
                return {
                  icon: iconProps => {
                    return (
                      <GreenSwitch
                        size="small"
                        checked={rowData ? !rowData.disabled : true}
                        color="primary"
                      />
                    );
                  },
                  onClick: (event, rowData) => {
                    state.data.forEach((d, idx) => {
                      if (rowData.id === d.id) {
                        let newRowData = { ...d, disabled: !rowData.disabled };
                        setState(prevState => {
                          const data = [...prevState.data];
                          data[idx] = newRowData;
                          return { ...prevState, data };
                        });

                        mapDispatch({
                          type: "REMOVE_MARKER",
                          payload: newRowData.id
                        });
                        if (
                          newRowData.latitude !== undefined &&
                          newRowData.longitude !== undefined
                        ) {
                          mapDispatch({
                            type: "ADD_MARKER",
                            payload: newRowData
                          });
                        }
                      }
                    });
                  },
                  tooltip: "Enable/Disable"
                };
              },
              {
                icon: "gps_fixed",
                tooltip: "Set location",
                onClick: (event, rowData) => {
                  mapDispatch({
                    type: "SET_MARKER_BEING_EDITED",
                    payload: rowData
                  });
                  if (
                    rowData.latitude !== undefined &&
                    rowData.longitude !== undefined
                  ) {
                    mapDispatch({
                      type: "PAN_TO",
                      payload: [rowData.latitude, rowData.longitude]
                    });
                  }
                }
              }
            ]}
            options={{
              pageSize: 20,
              showTextRowsSelected: true,
              maxBodyHeight: "38vh",
              exportButton: true,
              toolbar: true,
              padding: "dense"
            }}
            icons={{
              //Add: CreateIcon,
              Export: SaveIcon
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ position: "relative", height: "40vh" }}>
          <Map />
        </Grid>
      </Grid>
    </>
  );
}
