import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";
// styles
import useStyles from "./styles";
// components
import Header from "../Header";
import Sidebar from "../Sidebar";
// pages
import Destinations from "../../pages/destinations";
import Depots from "../../pages/depots";
import Routing from "../../pages/routing";
// context
import { useLayoutState } from "../../context/LayoutContext";
import { useLibraryDispatch } from "../../context/LibraryContext";

function Layout(props) {
  var classes = useStyles();
  const libraryDispatch = useLibraryDispatch();

  // global
  var layoutState = useLayoutState();

  React.useEffect(() => {
    libraryDispatch({ type: "INIT_DATA" });
  }, []);

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/route" component={Routing} />
            <Route
              exact
              path="/app/library"
              render={() => <Redirect to="/app/library/destinations" />}
            />
            <Route path="/app/library/destinations" component={Destinations} />
            <Route path="/app/library/depots" component={Depots} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
