import React, { useState } from "react";
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "./styles";
import useMapStyles from "../../components/Map/styles";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import CallSplit from "@material-ui/icons/CallSplit";
import classNames from "classnames";
import { useMapDispatch } from "../../context/MapContext";
import { Typography } from "../Wrappers";
import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreIcon } from "@material-ui/icons";
import { generateGpxFile } from "../../util/gpx";
import { secondsToHHMMSS } from "../../util/time";
import { SendErrorNotification } from "../App";

export default function RouteCollection(props) {
  const mapDispatch = useMapDispatch();

  const classes = useStyles();
  const mapClasses = useMapStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

  return props.routingResponse ? (
    <Typography size="sm">
      <Divider className={classes.divider} />
      <div>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className={classes.routeListHeader}
            >
              Total demand: {props.routingResponse.demandCovered}, distance:{" "}
              {printDistance(
                props.routingResponse.distance,
                props.routingResponse.distanceQuantity
              )}
              <IconButton
                color="primary"
                classes={{ root: classes.routeListMoreButton }}
                onClick={() => setMoreMenuOpen(true)}
                buttonRef={setMoreButtonRef}
              >
                <MoreIcon />
              </IconButton>
            </ListSubheader>
          }
          className={classes.root}
          classKey="dense"
        >
          {props.routingResponse.depotRoutes.map((dr, drIdx) => {
            return (
              <>
                <ListItem
                  key={`DEPOT_${dr.depot.id}_${drIdx}`}
                  button
                  className={classes.listItem}
                  onClick={() => {
                    mapDispatch({
                      type: "PAN_TO",
                      payload: dr.depot.coordinates
                    });
                  }}
                >
                  <div
                    className={`${classes.listIcon} ${mapClasses.depotIcon}`}
                  >
                    {dr.depot.name
                      .split(" ")
                      .map(i => i.charAt(0))
                      .slice(0, 3)
                      .join("")
                      .toUpperCase()}
                  </div>
                  <ListItemText
                    primary={`${dr.depot.name} demand: ${
                      dr.demandCovered
                    } (${printDistance(
                      dr.distance,
                      props.routingResponse.distanceQuantity
                    )})`}
                  />
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {dr.routes.map((route, routeIdx) => {
                      return (
                        <>
                          <ListItem
                            key={`DEPOT_${dr.depot.id}_${drIdx}_ROUTE_${routeIdx}`}
                            button
                            classes={{
                              root: classNames(
                                classes.listItem,
                                classes.listItemNested
                              )
                            }}
                          >
                            <div className={`${classes.listIcon}`}>
                              <CallSplit />
                            </div>
                            <ListItemText
                              primary={`Route demand: ${
                                route.demandCovered
                              } (${printDistance(
                                route.distance,
                                props.routingResponse.distanceQuantity
                              )})`}
                            />
                          </ListItem>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {route.customers.map((customer, customerIdx) => {
                                return (
                                  <ListItem
                                    key={`DEPOT_${dr.depot.id}_${drIdx}_ROUTE_${routeIdx}_CUSTOMER_${customer.id}_${customerIdx}`}
                                    button
                                    classes={{
                                      root: classNames(
                                        classes.listItem,
                                        classes.listItemDoubleNested
                                      )
                                    }}
                                    onClick={() => {
                                      mapDispatch({
                                        type: "PAN_TO",
                                        payload: customer.coordinates
                                      });
                                    }}
                                  >
                                    <div
                                      className={`${classes.listIcon} ${mapClasses.destinationIcon}`}
                                    >
                                      {customer.name
                                        ? customer.name
                                            .split(" ")
                                            .map(i => i.charAt(0))
                                            .slice(0, 3)
                                            .join("")
                                            .toUpperCase()
                                        : ""}
                                    </div>
                                    <ListItemText primary={customer.name} />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Collapse>
                        </>
                      );
                    })}
                  </List>
                </Collapse>
              </>
            );
          })}

          {/*<ListItem button className={classes.listItem}>*/}
          {/*    <div className={`${classes.listIcon} ${mapClasses.depotIcon}`}>*/}
          {/*        PS*/}
          {/*    </div>*/}
          {/*    <ListItemText primary="Pirmas sandelis" />*/}
          {/*</ListItem>*/}
          {/*<Collapse in={open} timeout="auto" unmountOnExit>*/}
          {/*    <List component="div" disablePadding>*/}
          {/*        <ListItem button classes={{*/}
          {/*            root: classNames(classes.listItem, classes.listItemNested),*/}
          {/*        }} >*/}
          {/*            <div className={`${classes.listIcon}`}>*/}
          {/*                <CallSplit />*/}
          {/*            </div>*/}
          {/*            <ListItemText primary="Route 1" />*/}
          {/*        </ListItem>*/}
          {/*        <Collapse in={open} timeout="auto" unmountOnExit>*/}
          {/*            <List component="div" disablePadding>*/}
          {/*                <ListItem button classes={{*/}
          {/*                    root: classNames(classes.listItem, classes.listItemDoubleNested),*/}
          {/*                }} >*/}
          {/*                    <div className={`${classes.listIcon} ${mapClasses.destinationIcon}`}>*/}
          {/*                        K1*/}
          {/*                    </div>*/}
          {/*                    <ListItemText primary="Klientas 1 " />*/}
          {/*                </ListItem>*/}
          {/*                <ListItem button classes={{*/}
          {/*                    root: classNames(classes.listItem, classes.listItemDoubleNested),*/}
          {/*                }} >*/}
          {/*                    <div className={`${classes.listIcon} ${mapClasses.destinationIcon}`}>*/}
          {/*                        K1*/}
          {/*                    </div>*/}
          {/*                    <ListItemText primary="Klientas 1" />*/}
          {/*                </ListItem>*/}
          {/*            </List>*/}
          {/*        </Collapse>*/}

          {/*        <ListItem button classes={{*/}
          {/*            root: classNames(classes.listItem, classes.listItemNested),*/}
          {/*        }} >*/}
          {/*            <div className={`${classes.listIcon}`}>*/}
          {/*                <CallSplit />*/}
          {/*            </div>*/}
          {/*            <ListItemText primary="Route 2" />*/}
          {/*        </ListItem>*/}
          {/*        <Collapse in={open} timeout="auto" unmountOnExit>*/}
          {/*            <List component="div" disablePadding>*/}
          {/*                <ListItem button classes={{*/}
          {/*                    root: classNames(classes.listItem, classes.listItemDoubleNested),*/}
          {/*                }} >*/}
          {/*                    <div className={`${classes.listIcon} ${mapClasses.destinationIcon}`}>*/}
          {/*                        K1*/}
          {/*                    </div>*/}
          {/*                    <ListItemText primary="Klientas 3 " />*/}
          {/*                </ListItem>*/}
          {/*                <ListItem button classes={{*/}
          {/*                    root: classNames(classes.listItem, classes.listItemDoubleNested),*/}
          {/*                }} >*/}
          {/*                    <div className={`${classes.listIcon} ${mapClasses.destinationIcon}`}>*/}
          {/*                        K1*/}
          {/*                    </div>*/}
          {/*                    <ListItemText primary="Klientas 4" />*/}
          {/*                </ListItem>*/}
          {/*            </List>*/}
          {/*        </Collapse>*/}
          {/*    </List>*/}
          {/*</Collapse>*/}
        </List>
        <Menu
          id="route-list-more-menu"
          open={isMoreMenuOpen}
          anchorEl={moreButtonRef}
          onClose={() => setMoreMenuOpen(false)}
          disableAutoFocusItem
        >
          <MenuItem
            onClick={() => {
              if (props.routingResponse.depotRoutes.length > 0) {
                let tracks = [];
                let waypoints = [];
                props.routingResponse.depotRoutes.forEach(dr => {
                  waypoints.push({
                    name: `Depot ${dr.depot.name}`,
                    coordinates: dr.depot.coordinates
                  });
                  dr.routes.forEach(route => {
                    route.customers.map((customer, customerIdx) => {
                      waypoints.push({
                        name: `Destination ${customer.name}`,
                        coordinates: customer.coordinates
                      });
                    });
                    tracks.push({
                      name: `Route from ${dr.depot.name} of ${printDistance(
                        route.distance,
                        props.routingResponse.distanceQuantity
                      )}`,
                      polyline: route.polyline
                    });
                  });
                });
                handleGPXDownload("roadgen.net route", waypoints, tracks);
              } else {
                SendErrorNotification("Could not export empty route to GPX");
              }
              setMoreMenuOpen(false);
            }}
          >
            <Typography>Export as GPX</Typography>
          </MenuItem>
        </Menu>
      </div>
    </Typography>
  ) : (
    []
  );
}

// ##############################################
function printDistance(distance, quantity) {
  if (quantity === "TIME") {
    return secondsToHHMMSS(distance);
  }
  return String(Math.round(distance / 10) / 100) + "km";
}

function handleGPXDownload(name, waypoints, tracks) {
  const xmlString = generateGpxFile(name, waypoints, tracks);
  const pom = document.createElement("a");
  const blob = new Blob([xmlString], { type: "text/plain" });
  pom.setAttribute("href", window.URL.createObjectURL(blob));
  pom.setAttribute("download", name + ".gpx");
  pom.click();
}
