import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  mapContainer: {
    height: "100%"
    //margin: -theme.spacing(1) * 3,
  },
  destinationIcon: {
    height: "25px !important",
    width: "25px !important",
    "min-height": "25px !important",
    "min-width": "25px !important",
    "border-radius": "100%",
    background: "#205723",
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
    color: "#fff",
    font: "11px/1.5 Arial"
  },
  destinationIconDisabled: {
    height: "25px !important",
    width: "25px !important",
    "min-height": "25px !important",
    "min-width": "25px !important",
    "border-radius": "100%",
    background: "#bdbdbd",
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
    color: "#000",
    font: "11px/1.5 Arial"
  },

  depotIcon: {
    height: "25px !important",
    width: "25px !important",
    "min-height": "25px !important",
    "min-width": "25px !important",
    "border-radius": "100%",
    background: "#0d47a1",
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
    color: "#fff",
    font: "11px/1.5 Arial"
  },
  depotIconDisabled: {
    height: "25px !important",
    width: "25px !important",
    "min-height": "25px !important",
    "min-width": "25px !important",
    "border-radius": "100%",
    background: "#78909c",
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
    color: "#fff",
    font: "11px/1.5 Arial"
  }
}));
