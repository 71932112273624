import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  routeCollectionContainer: {
    // display: "flex",
    // alignItems: "center",
    // flexGrow: 1,
    // paddingBottom: theme.spacing(1),
  },
  formContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "left",
    [theme.breakpoints.down("md")]: {
      width: "50%"
    },
    marginBottom: theme.spacing(2)
  },
  formButtons: {
    width: "100%",
    //marginTop: theme.spacing(2),
    //display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));
