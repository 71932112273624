import React, { useEffect, useState } from "react";

import Axios from "axios";

// components
import Map from "../../components/Map/Map";
import {
  Grid,
  Fab,
  TextField,
  Button,
  CircularProgress,
  Divider
} from "@material-ui/core";
import Widget from "../../components/Widget";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import RouteCollection from "../../components/RouteCollection";

// styles
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";

//icons
import NavigationIcon from "@material-ui/icons/Navigation";
import { useMapDispatch } from "../../context/MapContext";
import { useLibraryState } from "../../context/LibraryContext";
import Notification from "../../components/Notification";
import { Close as CloseIcon } from "@material-ui/icons";
import { SendErrorNotification } from "../../components/App";

export default function Routing() {
  const classes = useStyles();

  const [vehicleCapacity, setVehicleCapacity] = React.useState("10");
  const [distanceQuantity, setDistanceQuantity] = React.useState("LENGTH");
  const [drivingLength, setDrivingLength] = React.useState("500");
  const [drivingTime, setDrivingTime] = React.useState("8:00");
  const [routingResponse, setRoutingResponse] = React.useState(undefined);

  var [isLoading, setIsLoading] = useState(false);

  const libraryState = useLibraryState();
  const mapDispatch = useMapDispatch();

  useEffect(
    function onMount() {
      libraryState.destinations.forEach((d, i) => {
        if (d.latitude !== undefined && d.longitude !== undefined) {
          mapDispatch({
            type: "ADD_MARKER",
            payload: d
          });
        }
      });

      libraryState.depots.forEach((d, i) => {
        if (d.latitude !== undefined && d.longitude !== undefined) {
          mapDispatch({
            type: "ADD_MARKER",
            payload: d
          });
        }
      });

      // Cleanup on potential onmount
      return () => {
        mapDispatch({
          type: "CLEAR",
          payload: undefined
        });
      };
    },
    [libraryState.destinations, libraryState.depots]
  );

  useEffect(
    function onDepotRoutesChange() {
      if (routingResponse && routingResponse.depotRoutes.length > 0) {
        let routes = [];
        routingResponse.depotRoutes.forEach(dr => {
          routes.push(...dr.routes);
        });
        mapDispatch({ type: "SET_ROUTES", payload: routes });
        return;
      }
      mapDispatch({ type: "SET_ROUTES", payload: [] });
    },
    [routingResponse]
  );

  function handleRoute() {
    requestMultiDepotRouter();
  }

  async function requestMultiDepotRouter() {
    let maxDrivingTime = drivingLength * 1000;
    if (distanceQuantity === "TIME") {
      const drivingTimeParts = drivingTime.split(":");
      maxDrivingTime = drivingTimeParts[0] * 3600 + drivingTimeParts[1] * 60;
    }

    let req = {
      distanceQuantity: distanceQuantity,
      vehicleParameters: {
        count: 1000,
        capacity: vehicleCapacity,
        maxDrivingTime: maxDrivingTime
      },
      depots: libraryState.depots
        .filter(d => !d.disabled && d.latitude && d.longitude)
        .map(d => {
          return {
            id: d.id,
            name: d.name,
            coordinates: { lat: d.latitude, lon: d.longitude }
          };
        }),
      customers: libraryState.destinations
        .filter(c => !c.disabled && c.latitude && c.longitude)
        .map(d => {
          return {
            id: d.id,
            name: d.name,
            demand: d.demand < 1 ? 1 : Math.round(d.demand),
            coordinates: { lat: d.latitude, lon: d.longitude }
          };
        })
    };

    try {
      setRoutingResponse(undefined);
      setIsLoading(true);
      let res = await Axios.post(
        "https://roadgen.net/api/v20.1.0/routings/multi-depot/requests",
        JSON.stringify(req),
        { headers: { "Content-Type": "application/json" } }
      );
      setRoutingResponse(res.data);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        SendErrorNotification(e.response.data.message);
        return;
      }
      SendErrorNotification(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Widget disableWidgetMenu={true}>
            <div className={classes.routeCollectionContainer}>
              <div className={classes.formContainer}>
                <form noValidate autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl>
                        <InputLabel htmlFor="component-simple">
                          Max demand
                        </InputLabel>
                        <Input
                          id="demand"
                          value={vehicleCapacity}
                          onChange={e => setVehicleCapacity(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Distance quantity
                        </FormLabel>
                        <RadioGroup
                          aria-label="Distance quantity"
                          name="distance-quantity"
                          value={distanceQuantity}
                          onChange={e => setDistanceQuantity(e.target.value)}
                        >
                          <FormControlLabel
                            value="LENGTH"
                            control={<Radio color="default" />}
                            label="Length"
                          />
                          {distanceQuantity === "LENGTH" && (
                            <TextField
                              label="Max driving distance"
                              id="driving-length"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    km
                                  </InputAdornment>
                                )
                              }}
                              value={drivingLength}
                              onChange={e => setDrivingLength(e.target.value)}
                            />
                          )}
                          <FormControlLabel
                            value="TIME"
                            control={<Radio color="default" />}
                            label="Time"
                          />
                          {distanceQuantity === "TIME" && (
                            <TextField
                              id="driving-time"
                              label="Max driving time"
                              type="time"
                              defaultValue="08:00"
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                step: 600 // 5 min
                              }}
                              onChange={e => setDrivingTime(e.target.value)}
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <div className={classes.formButtons}>
                        {isLoading ? (
                          <CircularProgress
                            size={26}
                            className={classes.loginLoader}
                          />
                        ) : (
                          <Fab
                            variant="extended"
                            size="medium"
                            color="primary"
                            aria-label="add"
                            className={classes.margin}
                            onClick={handleRoute}
                          >
                            <NavigationIcon className={classes.extendedIcon} />
                            Optimize
                          </Fab>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <RouteCollection routingResponse={routingResponse} />
            </div>
          </Widget>
        </Grid>

        <Grid
          item
          lg={9}
          md={8}
          sm={6}
          xs={12}
          style={{ position: "relative", height: "91vh" }}
        >
          <Map />
        </Grid>
      </Grid>
    </>
  );
}
