import React, { useEffect, useRef } from "react";
import { Grid, Switch, Button } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import Papa from "papaparse";
import { difference } from "lodash";

// components
import Map from "../../components/Map";
import {
  SendErrorNotification,
  SendSuccessNotification
} from "../../components/App";
// context
import { useMapDispatch, useMapState } from "../../context/MapContext";
import {
  useLibraryDispatch,
  useLibraryState
} from "../../context/LibraryContext";
//styles
import { withStyles } from "@material-ui/core/styles";
//colors
import { green, grey } from "@material-ui/core/colors";
//util
import {
  search,
  reverse
  //reverse as reverseGeocode
} from "../../util/nominatimAxios";
//icons
import {
  Create as CreateIcon,
  FileCopy as FileCopyIcon,
  SaveAlt as SaveAltIcon,
  Save as SaveIcon
} from "@material-ui/icons";

const GreenSwitch = withStyles({
  switchBase: {
    color: grey[50],
    "&$checked": {
      color: green[400]
    },
    "&$checked + $track": {
      backgroundColor: green[400]
    }
  },
  checked: {},
  track: {}
})(Switch);

const CSV_IMPORT_REQUIRED_FIELDS = ["Name"];

export default function Destinations() {
  // global
  var mapDispatch = useMapDispatch();
  var mapState = useMapState();
  var libraryState = useLibraryState();
  var libraryDispatch = useLibraryDispatch();

  //local
  const $uploadCSVInput = React.useRef(null);
  const [state, setState] = React.useState({
    columns: [
      {
        title: "Disabled",
        field: "disabled",
        hidden: true,
        editable: "never",
        type: "boolean"
      },
      { title: "Name", field: "name" },
      { title: "Demand", field: "demand", type: "numeric", emptyValue: "1" },
      { title: "Description", field: "description" },
      { title: "Address", field: "address" },
      {
        title: "Latitude",
        field: "latitude",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Longitude",
        field: "longitude",
        type: "numeric",
        editable: "never"
      }
    ],
    data: []
  });

  useEffect(function onMount() {
    const data = libraryState.destinations;
    data.forEach((d, i) => {
      if (d.latitude !== undefined && d.longitude !== undefined) {
        mapDispatch({
          type: "ADD_MARKER",
          payload: d
        });
      }
    });

    setState(prevState => {
      return { ...prevState, data };
    });

    // Cleanup on potential onmount
    return () => {
      mapDispatch({
        type: "CLEAR",
        payload: undefined
      });
    };
  }, []);

  useEffect(
    function onDestinationsChange() {
      libraryDispatch({
        type: "UPDATE_DESTINATIONS",
        payload: state.data
      });
    },
    [state.data]
  );

  useEffect(
    function onLatLngChange() {
      mapState.markers.forEach(m => {
        state.data.forEach((d, idx) => {
          if (m.id === d.id) {
            if (d.latitude === m.latitude || d.longitude === m.longitude) {
              return;
            }
            let newDest = {
              ...d,
              latitude: m.latitude,
              longitude: m.longitude
            };
            setState(prevState => {
              const data = [...prevState.data];
              data[idx] = newDest;
              return { ...prevState, data };
            });
            scheduleAddressUpdate(idx, d, newDest);
          }
        });
      });
    },
    [mapState.markers]
  );

  function addDestination(newDestination) {
    setState(prevState => {
      const data = [...prevState.data];
      data.push(identifiedDestination(newDestination, data.length));
      return { ...prevState, data };
    });
    if (
      newDestination.latitude !== undefined &&
      newDestination.longitude !== undefined
    ) {
      mapDispatch({
        type: "ADD_MARKER",
        payload: newDestination
      });
    }
    scheduleLocationUpdate(
      state.data.length - 1,
      newDestination,
      newDestination
    );
  }

  function updateDestinationAt(idx, oldDestination, updatedDestination) {
    let newData = identifiedDestination(updatedDestination, idx);

    setState(prevState => {
      const data = [...prevState.data];
      data[idx] = newData;
      return { ...prevState, data };
    });
    mapDispatch({
      type: "REMOVE_MARKER",
      payload: newData.id
    });
    if (!!newData.latitude && !!newData.longitude) {
      mapDispatch({
        type: "ADD_MARKER",
        payload: newData
      });
    }

    scheduleLocationUpdate(idx, oldDestination, newData);
  }

  function scheduleLocationUpdate(idx, oldDestination, newData) {
    if (!scheduleDestinationPositionUpdate(idx, oldDestination, newData)) {
      scheduleAddressUpdate(idx, oldDestination, newData);
    }
  }

  function scheduleDestinationPositionUpdate(idx, oldDestination, newData) {
    if (
      !!newData.address &&
      (!newData.latitude ||
        !newData.longitude ||
        !oldDestination.address ||
        oldDestination.address !== newData.address)
    ) {
      //TODO:
      search({
        q: newData.address,
        addressdetails: "1",
        limit: 1
      })
        .then(res => {
          if (res.length > 0) {
            newData.latitude = res[0].lat;
            newData.longitude = res[0].lon;
            newData.address = res[0].display_name;
            updateDestinationAt(idx, newData, newData);
          } else {
            SendErrorNotification(
              "Unable to find position for address: '" + newData.address + "'"
            );
          }
        })
        .catch(e =>
          SendErrorNotification(
            "Failed find position for address: '" +
              newData.address +
              "'. Reason: " +
              e.message
          )
        );
      return true;
    }

    return false;
  }

  function scheduleAddressUpdate(idx, oldDestination, newData) {
    if (
      !!newData.latitude &&
      !!newData.longitude &&
      (!oldDestination.latitude ||
        !oldDestination.longitude ||
        oldDestination.latitude !== newData.latitude ||
        oldDestination.longitude !== newData.longitude)
    ) {
      //TODO:
      reverse(
        {
          addressdetails: "1",
          limit: 1
        },
        newData.latitude,
        newData.longitude
      )
        .then(res => {
          if (!!res) {
            newData.address = res.display_name;
            updateDestinationAt(idx, newData, newData);
          } else {
            SendErrorNotification(
              "Unable to find address for position: {lat: '" +
                newData.latitude +
                "', lon: '" +
                newData.longitude +
                "'}"
            );
          }
        })
        .catch(e =>
          SendErrorNotification(
            "Unable to find address for position: {lat: '" +
              newData.latitude +
              "', lon: '" +
              newData.longitude +
              "'}"
          )
        );
    }
  }

  function identifiedDestination(destination, index) {
    return {
      ...destination,
      type: "DESTINATION",
      id: "DESTINATION_" + index
    };
  }

  function onClickCSVUploadButton() {
    if ($uploadCSVInput.current) {
      $uploadCSVInput.current.click();
    }
  }

  function resetCSVUploadButton() {
    if ($uploadCSVInput.current) {
      $uploadCSVInput.current.value = "";
    }
  }

  function onSelectCSVFile(e) {
    if (e.currentTarget.files) {
      const file = Array.from(e.currentTarget.files)[0];

      parseCSVFile(file);
    }
    resetCSVUploadButton();
  }

  var parseCSVFile = file => {
    Papa.parse(file, {
      header: true,
      complete: onCSVParseComplete,
      error: error => {
        SendErrorNotification("Failed to parse CSV: " + error.message);
      }
    });
  };

  var onCSVParseComplete = res => {
    const parsedCSVHeader = res.meta.fields;
    const parsedCSVBody = res.data;
    const missingFields = difference(
      CSV_IMPORT_REQUIRED_FIELDS,
      parsedCSVHeader
    );
    if (missingFields.length > 0) {
      missingFields.forEach(field => {
        SendErrorNotification(
          "Missing required field '" + field + "' in the CSV file"
        );
      });

      return;
    }
    let imported = 0;
    let updated = 0;
    parsedCSVBody.forEach(CSVRow => {
      let importedDestination = {
        name: CSVRow["Name"],
        demand: CSVRow["Demand"],
        description: CSVRow["Description"],
        address: CSVRow["Address"],
        latitude: CSVRow["Latitude"],
        longitude: CSVRow["Longitude"]
      };

      if (!importedDestination.name) {
        return;
      }

      let exists = false;
      state.data.forEach((d, i) => {
        if (d.name === importedDestination.name) {
          exists = true;
          updateDestinationAt(i, d, importedDestination);
          updated++;
        }
      });

      if (!exists) {
        addDestination(importedDestination);
        imported++;
      }
    });
    if (imported > 0) {
      SendSuccessNotification("Imported destinations: " + imported);
    }
    if (updated > 0) {
      SendSuccessNotification("Updated destinations:" + updated);
    }
    if (imported === 0 && updated === 0) {
      SendErrorNotification("CSV is empty");
    }
  };

  return (
    <>
      <input
        data-cy="csv-file-upload-input"
        ref={$uploadCSVInput}
        id="upload"
        type="file"
        accept=".csv"
        onChange={onSelectCSVFile}
        //onClick={resetInputValue}
        style={{
          position: "absolute",
          width: "100%",
          visibility: "hidden"
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MaterialTable
            title="Destinations"
            columns={state.columns}
            data={state.data}
            editable={{
              onRowAdd: newData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    addDestination(newData);
                  }, 200);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      updateDestinationAt(
                        oldData.tableData.id,
                        oldData,
                        newData
                      );
                    }
                  }, 200);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setState(prevState => {
                      const data = [...prevState.data];
                      data.splice(oldData.tableData.id, 1);
                      return { ...prevState, data };
                    });
                    mapDispatch({
                      type: "REMOVE_MARKER",
                      payload: oldData.id
                    });
                  }, 200);
                })
            }}
            actions={[
              rowData => {
                return {
                  icon: iconProps => {
                    return (
                      <GreenSwitch
                        size="small"
                        checked={rowData ? !rowData.disabled : true}
                        color="primary"
                      />
                    );
                  },
                  onClick: (event, rowData) => {
                    state.data.forEach((d, idx) => {
                      if (rowData.id === d.id) {
                        let newRowData = { ...d, disabled: !rowData.disabled };
                        setState(prevState => {
                          const data = [...prevState.data];
                          data[idx] = newRowData;
                          return { ...prevState, data };
                        });

                        mapDispatch({
                          type: "REMOVE_MARKER",
                          payload: newRowData.id
                        });
                        if (
                          newRowData.latitude !== undefined &&
                          newRowData.longitude !== undefined
                        ) {
                          mapDispatch({
                            type: "ADD_MARKER",
                            payload: newRowData
                          });
                        }
                      }
                    });
                  },
                  tooltip: "Enable/Disable"
                };
              },
              {
                icon: "gps_fixed",
                tooltip: "Set location",
                onClick: (event, rowData) => {
                  mapDispatch({
                    type: "SET_MARKER_BEING_EDITED",
                    payload: rowData
                  });
                  if (
                    rowData.latitude !== undefined &&
                    rowData.longitude !== undefined
                  ) {
                    mapDispatch({
                      type: "PAN_TO",
                      payload: [rowData.latitude, rowData.longitude]
                    });
                  }
                }
              },
              {
                icon: "cloud_upload",
                tooltip: "Import CSV",
                isFreeAction: true,
                onClick: onClickCSVUploadButton
              }
            ]}
            options={{
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 50, 100, 200],
              showTextRowsSelected: true,
              maxBodyHeight: "38vh",
              exportButton: true,
              toolbar: true,
              padding: "dense"
            }}
            icons={{
              //Add: CreateIcon,
              Export: SaveIcon
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ position: "relative", height: "40vh" }}>
          <Map />
        </Grid>
      </Grid>
    </>
  );
}
