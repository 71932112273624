import Axios from "axios";

const API_ENDPOINT = "https://nominatim.openstreetmap.org";

var query = async function(path, params) {
  try {
    let res = await Axios.get(
      API_ENDPOINT + "/" + path,
      {
        params: {
          format: "json",
          ...params
        }
      },
      {
        headers: { "Content-Type": "application/json" }
      }
    );
    //console.log(res.data);
    return res.data;
  } catch (e) {
    //console.log(e);
    throw e;
  }
};

export async function search(params) {
  return query("search", params);
}

export function reverse(params, latitude, longitude) {
  params.zoom = params.zoom || 18;
  params.lat = latitude;
  params.lon = longitude;

  return query("/reverse", params);
}
