import React from "react";

var MapStateContext = React.createContext();
var MapDispatchContext = React.createContext();

const initialState = {
  destinations: [],
  depots: [],
  markers: [],
  routes: [],
  panTo: undefined
};

function mapReducer(state, action) {
  switch (action.type) {
    case "ADD_MARKER":
      return {
        ...state,
        markers: [...state.markers, action.payload]
      };
    case "ENSURE_MARKER":
      let exists = false;
      let markers = state.markers.map(waypoint => {
        if (waypoint.id !== action.payload.id) {
          return waypoint;
        } else {
          exists = true;
          return action.payload;
        }
      });

      return exists
        ? { ...state, markers: markers }
        : {
            ...state,
            markers: [...state.markers, action.payload]
          };
    case "SET_MARKER_BEING_EDITED":
      return {
        ...state,
        markerBeingEdited: action.payload
      };
    case "REMOVE_MARKER":
      return {
        ...state,
        markers: state.markers.filter(w => w.id !== action.payload)
      };
    case "PAN_TO":
      return {
        ...state,
        panTo: action.payload
      };
    case "CLEAR":
      return initialState;
    case "SET_ROUTES":
      return {
        ...state,
        routes: action.payload
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function MapProvider({ children }) {
  var [state, dispatch] = React.useReducer(mapReducer, initialState);
  return (
    <MapStateContext.Provider value={state}>
      <MapDispatchContext.Provider value={dispatch}>
        {children}
      </MapDispatchContext.Provider>
    </MapStateContext.Provider>
  );
}

function useMapState() {
  var context = React.useContext(MapStateContext);
  if (context === undefined) {
    throw new Error("useMapState must be used within a MapProvider");
  }
  return context;
}

function useMapDispatch() {
  var context = React.useContext(MapDispatchContext);
  if (context === undefined) {
    throw new Error("useMapDispatch must be used within a MapProvider");
  }
  return context;
}

export { MapProvider, useMapState, useMapDispatch };

// ###########################################################
